import rewards from "@/api/rewards";
import MemberAPI from "../../api/members";
import { Loading } from "element-ui";

const state = {
  pagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  filter: {
    name: "",
    type: "",
    property: "",
    category: ""
  },
  form: {
    name: "",
    tnc: "",
    lang: "EN"
  },
  vouchers: [],
  voucher: {
    id: null,
    sku: "",
    category_id: null,
    value: null,
    type: "",
    start_date: null,
    end_date: null,
    expiry_at: null,
    expiry_type: "none",
    expiry_number: null,
    weight: 1000,
    limit_quantity: true,
    quantity: null,
    langs: [],
    property: [],
    property_name: [],
    image: "",
    status: false
  }
};

const getters = {};

const actions = {
  async redeemRewards({ commit, rootState }, payload) {
    try {
      const rewardName = encodeURI(payload.reward_name);
      
      const result = await rewards.redeemRewards({
        data: payload.data,
        action: `Admin ${rootState.auth.name} redeem reward ${rewardName} for ${payload.data.recipient_card_no} (${payload.recipient_email})`
      });
      return result;
    } catch (err) {
      throw err;
    }
  },
  getList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.getList({
        page: payload.page,
        filter: { search: payload.search }
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRewards({ commit, rootState }, payload) {
    let loadingInstance = Loading.service({
      fullscreen: true
    });
    return new Promise((resolve, reject) => {
      let action = "";
      let pageName = "";
      if (payload.module !== undefined) {
        action = `Admin: ${rootState.auth.name} view ${payload.module}`;
        pageName = "reward_list";
      }
      rewards
        .getRewards({
          page: payload.page,
          categoryId: payload.categoryId,
          name: state.filter.name,
          type: state.filter.type,
          category: state.filter.category,
          property: state.filter.property,
          action: action,
          pageName: pageName
        })
        .then(response => {
          commit("GET_VOUCHERS_SUCCESS", response.data);
          loadingInstance.close();
          resolve(response);
        })
        .catch(error => {
          loadingInstance.close();
          reject(error);
        });
    });
  },
  getReward({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      rewards
        .getReward({
          id: payload.id,
          action: `Admin: ${rootState.auth.name} view ${payload.module}`,
          pageName: "reward_detail"
        })
        .then(response => {
          const data = response.data.data || [];
          commit("GET_VOUCHER_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_VOUCHERS_SUCCESS: (state, payload) => {
    state.vouchers = payload.data;
    const pagination = payload.meta.pagination;
    state.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  GET_VOUCHER_SUCCESS: (state, data) => {
    const activeLangs = data.langs.filter(
      item => item.lang === state.form.lang
    );
    if (activeLangs.length) {
      const activeLang = activeLangs[0];
      state.form.name = activeLang.name;
      state.form.tnc = activeLang.tnc;
    }
    state.voucher = {
      id: data.id,
      sku: data.sku,
      category_id: data.category_id,
      value: data.value,
      type: data.type,
      start_date: data.start_date ? data.start_date.split("T")[0] : "",
      end_date: data.end_date ? data.end_date.split("T")[0] : "",
      expiry_at: data.expiry_at ? data.expiry_at.split("T")[0] : "",
      expiry_type: data.expiry_type,
      expiry_number: data.expiry_number,
      weight: data.weight,
      limit_quantity: data.limit_quantity,
      quantity: data.quantity,
      langs: data.langs,
      property: data.property,
      property_name: data.property_name,
      image: data.image,
      status: data.status
    };
  },
  CLEAR_FILTER: state => {
    state.filter.name = "";
    state.filter.type = "";
    state.filter.category = "";
    state.filter.property = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
